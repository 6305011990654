import type { VueIslandConfig } from "@solvari/common-fe/helpers";

const leadDetailsPageIslands: VueIslandConfig = {
  changeProduct: () =>
    import("@/vue/apps/admin/components/leads/ChangeProduct.vue"),
  crossSell: () =>
    import("@/vue/apps/admin/components/leads/detail/cross-sell/CrossSell.vue"),
  changeInformation: () =>
    import("@/vue/apps/admin/components/leads/ChangeInformation.vue"),
  reviewInvite: () =>
    import("@/vue/apps/admin/components/leads/ReviewInvite.vue"),
  leadContactCard: () =>
    import(
      "@/vue/apps/admin/components/leads/detail/contact/LeadContactCard.vue"
    ),
};

export { leadDetailsPageIslands };
