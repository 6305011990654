import type { VueIslandConfig } from "@solvari/common-fe/helpers";

import { leadDetailsPageIslands } from "@/vue/apps/admin/pages/leads/leadDetailsPageIslands.ts";

function getAdminVueIslands() {
  const affiliate: VueIslandConfig = {
    affiliateLeadStatusCsv: () =>
      import(
        "@/vue/apps/admin/components/affiliate/AffiliateLeadStatusCsv.vue"
      ),
  };

  const leads: VueIslandConfig = {
    ...leadDetailsPageIslands,
    leadDetailsSteamIframe: () =>
      import("@/vue/apps/admin/pages/leads/LeadDetailsSteamIframe.vue"),
  };

  const contactForm: VueIslandConfig = {
    leadCall: () =>
      import("@/vue/apps/admin/components/leads/detail/contact/LeadCall.vue"),
  };

  const prospectOverview: VueIslandConfig = {
    prospectOverview: () =>
      import("@/vue/apps/admin/pages/prospect/ProspectOverview.vue"),
  };

  const orders: VueIslandConfig = {
    orderDetailsPage: () =>
      import("@/vue/apps/admin/pages/order/OrderDetailsPage.vue"),
  };

  const pro: VueIslandConfig = {
    customerEditAddress: () =>
      import("@/vue/apps/admin/components/customer/CustomerEditAddress.vue"),
    proFinancePage: () =>
      import("@/vue/apps/admin/pages/pro/ProFinancePage.vue"),
    proApiSettingsPage: () =>
      import("@/vue/apps/admin/pages/pro/ApiSettingsPage.vue"),
    proOrdersPage: () =>
      import("@/vue/apps/admin/pages/pro/order/OrdersPage.vue"),
    proOrderDetailsPage: () =>
      import("@/vue/apps/admin/pages/pro/order/ProOrderDetailsPage.vue"),
  };

  const salesPages: VueIslandConfig = {
    ordersPage: () => import("@/vue/apps/admin/pages/sales/OrdersPage.vue"),
    subscriptionsPage: () =>
      import("@/vue/apps/admin/pages/sales/SubscriptionsPage.vue"),
    plansPage: () => import("@/vue/apps/admin/pages/sales/PlansPage.vue"),
    createPlanPage: () =>
      import("@/vue/apps/admin/pages/sales/CreatePlanPage.vue"),
    showPlanPage: () => import("@/vue/apps/admin/pages/sales/ShowPlanPage.vue"),
  };

  const leadReclamation: VueIslandConfig = {
    inboxTabs: () => import("@/vue/apps/admin/components/InboxTabs.vue"),
  };

  return {
    ...affiliate,
    ...leads,
    ...contactForm,
    ...prospectOverview,
    ...orders,
    ...pro,
    ...salesPages,
    ...leadReclamation,
    addressIsland: () =>
      import("@/vue/apps/admin/components/AddressIsland.vue"),
  };
}

export { getAdminVueIslands };
