import { initAxiosInterceptors } from "@solvari/common-fe/composables";
import { initVueIslands } from "@solvari/common-fe/helpers";
import { initFontAwesome } from "@solvari/common-fe/integrations";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createPinia } from "pinia";
import PortalVue from "portal-vue";

import { initPrimeVueThemed, sModalPlugin } from "@solvari/components";
import { useI18nAdmin } from "@solvari/translations";
import { getEnv } from "@solvari/utils";

import { getAdminVueIslands } from "@/vue/apps/admin/pages";
import { useQueryClient } from "@/vue/apps/admin/plugins/vueQuery.ts";
import store from "@/vue/apps/admin/store/index.js";

import "@/vue/apps/admin/plugins/sentry.ts";

import "@/vue/apps/admin/styling/index.scss";
import "@solvari/components/blade/pro/chosen.css";
import "@solvari/components/blade/pro/s-company-badges/sCompanyBadge.css";
import "@solvari/components/blade/pro/s-company-card/sCompanyCard.css";
import "@solvari/components/blade/review/review-widget/reviewScore.css";

function main() {
  initFontAwesome();

  initAxiosInterceptors();

  initVueIslands(
    getAdminVueIslands(),
    (app) => {
      app.use(createPinia());
      app.use(store());
      app.use(sModalPlugin);
      app.use(VueQueryPlugin, {
        enableDevtoolsV6Plugin: true,
        queryClient: useQueryClient(),
      });
      app.use(PortalVue);
      initPrimeVueThemed(app);

      // TODO remove this i18n call when we use composition api more
      useI18nAdmin();
    },
    getEnv().config.localeCode,
  );
}

main();
